<template>
  <div class="drivers-mb-header">
    <div class="drivers-mb-header__inner">
      <div class="logo" @click="handleClickLogo">
        <Icon name="LogoPortTranzit" width="160px" height="auto" />
      </div>

      <div
        v-if="isSuperAdmin"
        class="el-dropdown-link"
        @click="handleOpenMobileMenu"
      >
        <Icon
          class="other-header-bottom__icon"
          name="IconMenuLine"
          height="30px"
          width="30px"
        />
      </div>
      <el-dropdown v-if="!isSuperAdmin && !isExporter" trigger="click">
        <span class="el-dropdown-link">
          <Icon
            class="other-header-bottom__icon"
            name="IconMenuLine"
            height="27px"
            width="21px"
          />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">Выход</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-if="isExporter" class="exporter-return" @click="toExporterQuotas">
        <span class="el-dropdown-link">
          <Icon name="IconLeftAngle" height="27px" width="27px" />
        </span>
        <span>Вернуться к квотам</span>
      </div>
    </div>
    <mobile-menu v-if="isSuperAdmin" />
  </div>
</template>

<script>
import {
  AUTH_LOGOUT,
  GET_IS_INCOMPLETE_PARKING,
  SET_DRAWER,
} from '@/store/actions'
import { MAIN_MENU } from '@/constants/drawers'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mobileDriverMenu, navigationRootList } from '@/constants/navigaiton'
import Icon from '@/UI/icon/Icon'
import MobileMenu from '@/components/drawer/mobile-menu/MobileMenu'

export default {
  components: {
    MobileMenu,
    Icon,
  },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeMenuItemId: null,
      menuItems: mobileDriverMenu,
    }
  },
  computed: {
    ...mapGetters({
      hideParkmanPage: GET_IS_INCOMPLETE_PARKING,
    }),
    navigationList() {
      if (!this.hideParkmanPage) {
        return navigationRootList
      }
      let parkmanIndex = navigationRootList.findIndex(
        item => item.name === 'Parkman',
      )
      let supervisorIndex = navigationRootList.findIndex(
        item => item.name === 'Supervisor',
      )
      let list = navigationRootList.slice()

      list.splice(parkmanIndex, 1)
      list.splice(supervisorIndex, 1)

      return list
    },
  },
  methods: {
    ...mapActions({ logout: AUTH_LOGOUT }),
    ...mapMutations({ setDrawer: SET_DRAWER }),
    handleClickRefresh() {
      this.$emit('clickRefresh')
    },
    handleClickLogo() {
      const urlHome = 'Home'

      urlHome !== this.$route.name && this.$router.push({ name: urlHome })
    },
    handleOpenMobileMenu() {
      this.setDrawer({
        name: MAIN_MENU,
        visible: true,
        data: { items: this.navigationList },
      })
    },
    // при возврате к квотам экспортера удаляем данные формы запроса таймслота,
    // чтобы они не подтягивались при последующем заходе на страницу бронирования
    toExporterQuotas() {
      localStorage.removeItem('get-slot-form')
      this.$router.push({ name: 'ExporterQuotas' })
    },
  },
}
</script>
<style lang="sass">
$backColor: #4BA7D8
$textColor: #fff
$activeColor: rgb(255, 255, 255)
.router-link-active
  background: $backColor
  font-weight: bold!important
.el-icon-own-menu-big
  display: block
  width: 30px
  height: 30px
  cursor: pointer
  position: relative
  margin: 0
  &:before
    content: ''
    position: absolute
    width: 30px
    height: 30px
    background: url("~@/assets/icons/menu-icon-white.svg") no-repeat
    background-size: contain
    left: 0

.drivers-mb-header
  background-color: $backColor
  border-top: 2px solid $backColor
  color: #333
  height: 42px
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 9999
  font-size: 12px
  @media(min-width: 360px)
    font-size: 16px
    height: 42px
  &__inner
    position: relative
    width: 100%
    height: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 12px 15px

    .logo
      cursor: pointer
  &__menu
    background-color: $backColor
    list-style: none
    padding: 0
    margin: 0
    width: 100%
    height: 100%
    display: flex
  &__menu-link
    fill: red!important
    color: inherit
    text-decoration: none
    text-align: center
    height: 100%
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-size: 13px
    svg
      fill: white
      margin-bottom: 4px

  &__menu-item
    background-color: $backColor
    flex-grow: 1
    position: relative
    width: 50%

    &.is-route
      color: $textColor
      text-decoration: none
      >.menu-box
        button, a
          font-weight: normal
    >.menu-box
      position: relative
      width: 100%
      height: 100%
      &:before
        content: ''
        position: absolute
      &.el_0:before
        top: 10px
        left: calc(50% - 9px)
        width: 17px
        height: 18px
        background-size: contain
        fill: white
      &.el_1:before
        top: 12px
        left: calc(50% - 18px)
        width: 36px
        height: 17px
        background-size: contain
        fill: white

      >.drivers-mb-header
        &__menu-btn, &__menu-link
          padding: 15px 0 5px
          margin: 0 auto
  &__submenu
    box-shadow: 0 0 5px #ccc
  &__submenu-item
    color: $textColor
    &.is-route
      color: $textColor
      text-decoration: none
      button, a
        font-weight: bold
  &__divider
    &.is-2
      background-color: #ccc

.exporter-return
  display: flex
  align-items: center
  gap: 5px
  color: white
  cursor: pointer
  svg
    stroke: white
  &:hover
    color: lightgray
    svg
      stroke: lightgray
</style>
