var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drivers-mb-header" },
    [
      _c(
        "div",
        { staticClass: "drivers-mb-header__inner" },
        [
          _c(
            "div",
            { staticClass: "logo", on: { click: _vm.handleClickLogo } },
            [
              _c("Icon", {
                attrs: {
                  name: "LogoPortTranzit",
                  width: "160px",
                  height: "auto",
                },
              }),
            ],
            1
          ),
          _vm.isSuperAdmin
            ? _c(
                "div",
                {
                  staticClass: "el-dropdown-link",
                  on: { click: _vm.handleOpenMobileMenu },
                },
                [
                  _c("Icon", {
                    staticClass: "other-header-bottom__icon",
                    attrs: {
                      name: "IconMenuLine",
                      height: "30px",
                      width: "30px",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isSuperAdmin && !_vm.isExporter
            ? _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c(
                    "span",
                    { staticClass: "el-dropdown-link" },
                    [
                      _c("Icon", {
                        staticClass: "other-header-bottom__icon",
                        attrs: {
                          name: "IconMenuLine",
                          height: "27px",
                          width: "21px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Выход")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isExporter
            ? _c(
                "div",
                {
                  staticClass: "exporter-return",
                  on: { click: _vm.toExporterQuotas },
                },
                [
                  _c(
                    "span",
                    { staticClass: "el-dropdown-link" },
                    [
                      _c("Icon", {
                        attrs: {
                          name: "IconLeftAngle",
                          height: "27px",
                          width: "27px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("Вернуться к квотам")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isSuperAdmin ? _c("mobile-menu") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }